import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import MatchTileHits from "@/game-destiny2/components/MatchTileHits.jsx";
import MatchTileScoreboard from "@/game-destiny2/components/MatchTileScoreboard.jsx";
import MatchTileStatistics from "@/game-destiny2/components/MatchTileStatistics.jsx";
import { bungieAsset } from "@/game-destiny2/utils/api.mjs";
import useActivity from "@/game-destiny2/utils/use-activity.mjs";
import DotIcon from "@/inline-assets/dot.svg";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";
import ShareButton from "@/shared/ShareButton.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { filterErrState, useEvalState } from "@/util/eval-state.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Match() {
  const {
    parameters: [bungieId, gameId],
  } = useRoute();
  const { t } = useTranslation();
  const {
    destiny2: { characters, profiles, match: matches },
  } = useSnapshot(readState);
  const $match = matches[gameId];
  const $profile = profiles[bungieId];
  const profile = useEvalState($profile);
  const match = useEvalState($match);
  const { isStory, isWorldEvent, mode } = useActivity(match);
  const guardians = profile?.memberships.flatMap?.((i) => i.guardians) ?? [];
  const player = match?.playerMatches.find?.((i) =>
    guardians.find((j) => j.characterId === i.guardian.characterId),
  );
  return (
    <SharedMatchLayout
      match={$match}
      title={
        typeof player?.won !== "undefined"
          ? player.won
            ? t("common:stats.victory", "Victory")
            : t("common:stats.defeat", "Defeat")
          : t("common:results.draw", "Draw")
      }
      image={bungieAsset(
        filterErrState(characters[bungieId]?.[player?.guardian.characterId])
          ?.character?.emblemPath ?? "",
      )}
      imageLink={`/destiny2/profile/${bungieId}`}
      borderColor="var(--shade3)"
      underTitle={
        <div className="flex align-center gap-2 color-shade2">
          {[
            isStory
              ? t(t("gen-destiny2:activityMode.campaign", "Campaign"))
              : isWorldEvent
                ? t("gen-destiny2:activityMode.worldEvent", "World Event")
                : undefined,
            mode,
            player?.guardian.class,
          ]
            .filter((i) => i)
            .map((i) => (
              <React.Fragment key={i}>
                <span className="type-callout color-shade2">{i}</span>
                <DotIcon style={{ color: "var(--shade3)" }} />
              </React.Fragment>
            ))}
          {!!match?.startedAt && (
            <div className="type-callout color-shade2">
              <TimeAgo
                date={new Date(match?.startedAt)}
                className={undefined}
              />
            </div>
          )}
          <DotIcon style={{ color: "var(--shade3)" }} />
          <ShareButton />
        </div>
      }
    >
      <MainColumnsContainer>
        <div
          className="span-1 flex direction-column"
          style={{ display: "flex" }} // This overrides MainColumnsContainer's display grid
        >
          <MatchTileScoreboard bungieId={bungieId} gameId={gameId} />
        </div>
        <div className="span-2">
          <MatchTileHits bungieId={bungieId} gameId={gameId} />
          <MatchTileStatistics bungieId={bungieId} gameId={gameId} />
        </div>
      </MainColumnsContainer>
    </SharedMatchLayout>
  );
}

export function meta([profileId]) {
  const profile = readState.destiny2.profiles[profileId];
  const name =
    !profile || profile instanceof Error ? "Unknown" : profile.bungieName;
  return {
    title: [
      "destiny2:meta.match.title",
      "{{name}}'s Match Stats – D2 – Blitz Destiny 2",
      { name },
    ],
    description: [
      "destiny2:meta.match.description",
      "Match Stats for {{name}}",
      { name },
    ],
  };
}
